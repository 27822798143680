<template>
    <div class="good-list">
        <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="url" width="120">
                <template v-slot="{ row }">
                    <el-image style="width: 100px; height: 100px" :src="row.thumb_url" :zoom-rate="1" fit="cover" />
                </template>
            </el-table-column>
            <el-table-column>
                <template v-slot="{ row }">
                    <div class="product-desc">
                        <h5>{{ row.title }}</h5>
                        <p>
                            買取価格：
                            <span>¥{{ row.price }}</span>
                            <em>円</em>
                        </p>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination-block">
            <el-pagination class="pagination" layout="prev, pager, next" :current-page="currentPage" :page-size="pageSize"
                @current-change="handleCurrentChange" :total="totalItems" />
        </div>
    </div>
</template>

<script>
import { ElPagination } from 'element-plus';
import axios from 'axios';

const tableData = [];

export default {
    name: 'ProductList',
    components: {
        ElPagination,
    },
    data() {
        return {
            tableData,
            currentPage: 1,
            pageSize: 10,
            totalItems: 0,
        };
    },
    created() {
        this.totalItems = tableData.length;
        this.fetchTableData();
    },
    methods: {
        fetchTableData() {
            axios.get('/api/products', {
                params: {
                    page: this.currentPage,
                }
            })
                .then(response => {
                    this.tableData = response.data.data;
                    this.totalItems = response.data.total;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        handleCurrentChange(newPage) {
            this.currentPage = newPage;
            this.fetchTableData();
        }
    }
};
</script>

<style>
.good-list {
    margin: 0 10px 0 10px;
}

.product-desc h5 {
    font-size: 14px;
    margin-left: 10px;
    font-weight: 100
}

.product-desc p {
    width: 100%;
    font-size: 12px;
    text-align: right
}

.product-desc p em {
    font-size: 24px
}

.product-desc p span {
    color: red;
    font-size: 24px
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.el-pagination__pager {
    text-align: center;
}
</style>
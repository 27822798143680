<template>
  <layout></layout>
  <div class="main">
    <article class="main-body">
      <ProductList></ProductList>
    </article>
    <article class="side-part">
      <div class="side-bar">
        <sidebar></sidebar>
      </div>
    </article>
  </div>
</template>

<script>
import ProductList from './components/ProductList.vue';
import layout from './layout/layout.vue';
import sidebar from './layout/sidebar.vue';

export default {
  name: 'App',
  components: {
    layout,
    sidebar,
    ProductList,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.main {
  display: flex;
  justify-content: space-between
}

.main-body {
  width: calc(100% - 280px);
  transition: .3s
}

.side-part {
  width: 250px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transition: .3s;
  z-index: 9
}

.side-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 170px
}

@media screen and (max-width: 800px) {
  .main-body {
    width: 100%
  }

  .side-part {
    position: fixed;
    background: #fff;
    padding: 10px;
    left: 0;
    transform: translate(-250px);
    height: 100vh;
    top: 0;
    z-index: 20;
    width: 250px
  }

  .side-bar {
    top: 0
  }
}
</style>

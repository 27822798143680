<template>
    <div class="sidebar">
        <a href="/uploads/20220815.pdf" target="_blank">
            <figure class="sidePoster">
                <img src="http://jp-one.co.jp/build/assets/sideposter.5662ec4a.jpg" alt="">
            </figure>
        </a>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'sidebar',
    components: {
    }
};
</script>

<style>
.sidebar {
    /* width: 300px; */
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    transition: .3s;
    z-index: 9;
}

img {
    max-width: 100%;
    height: auto;
}
</style>